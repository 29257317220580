/* eslint-disable */
import { createRouter, createWebHashHistory } from "vue-router";
// import cookie from "src/utilities/cookie.js";

// import pages
const Home = () => import("/src/pages/Home.vue");
const AboutUs = () => import("/src/pages/AboutUs/Index.vue");
const Plans = () => import("/src/pages/Plans/Index.vue");
const ContactSales = () => import("/src/pages/ContactSales/Index.vue");
const Support = () => import("/src/pages/Support/Index.vue");

//new brand creation
const Home2 = () => import("/src/pages/Home2.vue");

// coming soon
const ComingSoon = () => import("/src/pages/ComingSoon/Index.vue");

//Privacy Policy
const PrivacyPolicy = () => import("/src/pages/PrivacyPolicy/PrivacyPolicy.vue");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    alias: "/",
  },
  {
    path: "/new-home",
    name: "NewHome",
    component: Home2,
    alias: "/new-home",
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
    alias: "/about-us",
  },
  {
    path: "/plans",
    name: "Plans",
    component: Plans,
    alias: "/plans",
  },
  {
    path: "/contact-sales",
    name: "ContactSales",
    component: ContactSales,
    alias: "/contact-sales",
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    alias: "/support",
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
    alias: "/coming-soon",
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    alias: "/privacy-policy",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0,
      };
    }
  },
});

router.onError(error => {    
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
